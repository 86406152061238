import MultipleLayerSelect from '@/common/components/MultipleLayerSelect';
import PageTitle from '@/common/components/PageTitle';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import UserSelectInput from '@/common/components/UserSelectInput';
import { useApplicationContext } from '@/context/ApplicationContext';
import { ApplicationProvider_CompanySettingFragment } from '@/context/ApplicationContext.generated';
import { AssetMultiLayerSelect } from '@/modules/assets/components/AssetMultiLayerSelect';
import { useHierarchicalProducts } from '@/modules/products';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Spinner,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { lazy } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import WorkOrderPriorityRadio from '../../WorkOrderPriorityRadio';
import { WorkOrderRecentlyUsedValues } from '../../WorkOrderRecentlyUsedValues';
import WorkOrderStartEndDatePicker from '../../WorkOrderStartEndDatePicker';
import WorkOrderStatusRadio from '../../WorkOrderStatusRadio';
import { WorkOrderStoppageReasonPicker } from '../../WorkOrderStoppageReasonPicker';
import WorkOrderCheckListField from '../../checkList/WorkOrderCheckListField';
import CustomFieldItem from '../../customFields/CustomFieldItem';
import WorkOrderPartField from '../../parts/WorkOrderPartField';
import { useWorkOrderForm } from './WorkOrderForm.hooks';
import { WorkOrderFormProps } from './WorkOrderForm.types';
const WorkOrderCheckListCustomFieldList = lazy(
  () => import('../../checkList/WorkOrderCheckListCustomFieldList')
);

const WorkOrderForm = (props: WorkOrderFormProps) => {
  const { companySetting } = useApplicationContext();
  if (!companySetting) return null;
  return <InternalWorkOrderForm companySetting={companySetting} {...props} />;
};

type InternalWorkOrderFormProps = WorkOrderFormProps & {
  companySetting: ApplicationProvider_CompanySettingFragment;
};

export const InternalWorkOrderForm = (props: InternalWorkOrderFormProps) => {
  const {
    handleCancel,
    handlePause,
    assigneesInputHandler,
    displayCustomFields,
    onChangeProduct,
    onChangeAsset,
    onSubmit,
    onChangeWorkOrderStatus,
    onChangeWorkOrderPart,
    onChangeCheckLists,
    onChangeCheckListFormValue,
    handleAddCustomFieldFileValues,
    handleRemoveCustomFieldFileValue,
    handleUpdateCustomFieldFileValue,
    workOrderParts,
    selectedAssetId,
    selectedProductId,
    errors,
    handleSubmit,
    register,
    formData,
    fieldOrders,
    setCustomFieldTextLocalValues,
    setCustomFieldIntLocalValues,
    setCustomFieldFloatLocalValues,
    setCustomFieldSelectLocalValues,
    setCustomFieldDatetimeLocalValues,
    setCustomFieldDateLocalValues,
    setCustomFieldUserLocalValues,
    customFieldTextLocalValues,
    customFieldIntLocalValues,
    customFieldFloatLocalValues,
    customFieldSelectLocalValues,
    customFieldDatetimeLocalValues,
    customFieldDateLocalValues,
    customFieldUserLocalValues,
    priority,
    setPriority,
    checkListFormValue,
    assignees,
    methods,
    isSubmitting,
    checkLists,
    getFileUploadUrls,
    localCustomFieldFileValues,
    status,
  } = useWorkOrderForm(props);
  const { selectProductItems } = useHierarchicalProducts();
  const { isMobile, isDesktop } = useScreenInfos();
  const { t, t_errors } = useTranslation(TASK);

  const {
    cancel,
    onUpdatePart,
    workOrder,
    createCheckList,
    deleteCheckList,
    title = t('actions.create-task'),
    submitButton = t('actions.register'),
    usePauseButton = false,
    displayStatus = false,
    checkListTemplate,
    companySetting,
  } = props;
  const { accessCheckList, accessProduct } = companySetting;
  return (
    <Flex direction='column' bg='neutral.50' height='inherit'>
      <PageTitle
        title={title}
        titleSize='sm'
        minH={{ base: 'auto', md: '52px' }}
        maxH={{ base: 'auto', md: '52px' }}
        position='sticky'
        top={0}
        zIndex={10}
        leftChild={
          isMobile && (
            <IconButton
              onClick={() => cancel()}
              aria-label='Back'
              size='md'
              variant='ghost'
              icon={<MdClose />}
            />
          )
        }
      >
        {isMobile && (
          <Flex gap={2}>
            {usePauseButton && (
              <Button color='gray.500' onClick={handlePause} width='60px' variant='ghost' size='sm'>
                {t('actions.pause')}
              </Button>
            )}

            <Button
              colorScheme='primary'
              onClick={handleSubmit(onSubmit)}
              width='60px'
              variant='ghost'
              size='sm'
            >
              {submitButton}
            </Button>
          </Flex>
        )}
      </PageTitle>

      <Box as='form' onSubmit={handleSubmit(onSubmit)} flex='1' overflowY='scroll'>
        {displayStatus && (
          <Box alignItems='flex-start' my={4} mx={2}>
            <WorkOrderStatusRadio status={status} updateWorkOrderStatus={onChangeWorkOrderStatus} />
          </Box>
        )}

        {[...fieldOrders]
          .sort((a, b) => a.order - b.order)
          .map((fieldOrder) => {
            return (
              <Box key={fieldOrder.id} width='100%'>
                {fieldOrder.type === 'title' && (
                  <FormControl
                    isInvalid={!!errors.title}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <FormLabel htmlFor='title'>{t('title')}</FormLabel>
                    <Input id='title' {...register('title')} />
                    <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
                  </FormControl>
                )}

                {fieldOrder.type === 'description' && (
                  <FormControl
                    isInvalid={!!errors.description}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <FormLabel>{t('description')}</FormLabel>
                    <Textarea id='description' {...register('description')} />
                    <FormErrorMessage>
                      {errors.description && errors.description.message}
                    </FormErrorMessage>
                  </FormControl>
                )}

                {fieldOrder.type === 'asset' && (
                  <FormControl
                    isInvalid={!selectedAssetId && !!errors.assetId}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                    data-testid='work-order-form-asset'
                  >
                    <FormLabel>{t('pages.asset')}</FormLabel>

                    <SuspenseWithSpinner>
                      <AssetMultiLayerSelect value={selectedAssetId} onChange={onChangeAsset} />
                    </SuspenseWithSpinner>
                    <FormErrorMessage>{errors.assetId && errors.assetId.message}</FormErrorMessage>
                    <VStack mt={2} alignItems='start' gap={0}>
                      <Heading size='xs'>{t('form.recently-used')}</Heading>

                      <Flex minH='40px'>
                        {!formData ? (
                          <Spinner color='primary.500' size='sm' alignSelf='center' />
                        ) : (
                          <WorkOrderRecentlyUsedValues
                            items={formData.recentlyUsedWorkOrderAssets}
                            onClick={(item) => onChangeAsset(item.id)}
                          />
                        )}
                      </Flex>
                    </VStack>
                  </FormControl>
                )}

                {fieldOrder.type === 'due' && (
                  <FormControl
                    isInvalid={!!errors.dueDate}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <FormLabel>{t('date.due-date')}</FormLabel>
                    <Input id='due-date-at' type='date' {...register('dueDate')} />
                    <FormErrorMessage>
                      {errors.dueDate &&
                        (errors.dueDate.type === 'required'
                          ? t_errors('item-is-not-defined', { item: t('date.due-date') })
                          : errors.dueDate.message)}
                    </FormErrorMessage>
                  </FormControl>
                )}

                {accessProduct && fieldOrder.type === 'product' && (
                  <FormControl
                    isInvalid={!selectedProductId && !!errors.productId}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                    data-testid='work-order-form-product'
                  >
                    <FormLabel>{t('pages.product')}</FormLabel>
                    <MultipleLayerSelect
                      label={t('pages.product')}
                      value={selectedProductId}
                      options={selectProductItems}
                      onChange={(value) => onChangeProduct(value)}
                    />

                    <FormErrorMessage>
                      {errors.productId && errors.productId.message}
                    </FormErrorMessage>

                    <VStack mt={2} alignItems='start' gap={0}>
                      <Heading size='xs'>{t('form.recently-used')}</Heading>
                      <Flex minH='40px'>
                        {!formData ? (
                          <Spinner color='primary.500' size='sm' alignSelf='center' />
                        ) : (
                          <WorkOrderRecentlyUsedValues
                            items={formData.recentlyUsedWorkOrderProducts}
                            onClick={(item) => onChangeProduct(item.id)}
                          />
                        )}
                      </Flex>
                    </VStack>
                  </FormControl>
                )}

                {fieldOrder.type === 'assignee' && (
                  <FormControl
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                    data-testid='work-order-form-assignee'
                  >
                    <FormLabel>{t('assignee')}</FormLabel>
                    <UserSelectInput
                      defaultUserValues={assignees}
                      onChange={assigneesInputHandler}
                    />
                  </FormControl>
                )}

                {fieldOrder.type === 'priority' && (
                  <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4}>
                    <FormControl data-testid='work-order-form-priority'>
                      <FormLabel>{t('priority.title')}</FormLabel>
                      <WorkOrderPriorityRadio
                        priority={priority}
                        updateWorkOrderPriority={setPriority}
                      />
                    </FormControl>
                  </Box>
                )}

                {fieldOrder.type === 'stoppage' && (
                  <FormProvider {...methods}>
                    <WorkOrderStartEndDatePicker />
                  </FormProvider>
                )}

                {fieldOrder.type === 'stoppageReason' && (
                  <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4}>
                    <FormControl data-testid='work-order-form-stoppage-reason'>
                      <FormLabel>{t('stoppage-reason.title')}</FormLabel>
                      <Controller
                        control={methods.control}
                        name='stoppageReasonId'
                        render={({ field }) => (
                          <WorkOrderStoppageReasonPicker
                            assetId={selectedAssetId}
                            additionalItem={workOrder?.stoppageReason}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                )}

                {fieldOrder.type === 'checkList' && accessCheckList && (
                  <>
                    {checkListTemplate ? (
                      <SuspenseWithSpinner>
                        <WorkOrderCheckListCustomFieldList
                          isEdit
                          name={checkListTemplate.name}
                          description={checkListTemplate.description}
                          items={checkListTemplate.items}
                          checkListFormValue={checkListFormValue}
                          onChangeCheckListValue={onChangeCheckListFormValue}
                        />
                      </SuspenseWithSpinner>
                    ) : (
                      <FormControl
                        bg='neutral.0'
                        borderRadius='md'
                        px={4}
                        py={2}
                        mx={2}
                        my={4}
                        width='auto'
                      >
                        <FormLabel fontWeight='bold'>{t('pages.check-list')}</FormLabel>
                        <WorkOrderCheckListField
                          workOrderId={workOrder ? workOrder.id : undefined}
                          isEdit
                          checkLists={checkLists}
                          onChangeCheckLists={onChangeCheckLists}
                          createCheckList={createCheckList}
                          deleteCheckList={deleteCheckList}
                        />
                      </FormControl>
                    )}
                  </>
                )}

                {fieldOrder.type === 'part' && (
                  <FormControl
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <WorkOrderPartField
                      workOrderId={workOrder ? workOrder.id : undefined}
                      assetId={selectedAssetId}
                      workOrderParts={workOrderParts}
                      onChangeWorkOrderPart={onChangeWorkOrderPart}
                      onUpdatePart={onUpdatePart}
                    />
                  </FormControl>
                )}

                {fieldOrder.type === 'customField' &&
                  (() => {
                    const displayCustomField = displayCustomFields.find(
                      (entry) => entry.id === fieldOrder.customFieldId
                    );
                    return (
                      <>
                        {displayCustomField && (
                          <CustomFieldItem
                            assetId={selectedAssetId}
                            customField={displayCustomField}
                            customFieldTextValues={customFieldTextLocalValues}
                            customFieldIntValues={customFieldIntLocalValues}
                            customFieldFloatValues={customFieldFloatLocalValues}
                            customFieldSelectValues={customFieldSelectLocalValues}
                            customFieldDateValues={customFieldDateLocalValues}
                            customFieldDatetimeValues={customFieldDatetimeLocalValues}
                            customFieldUserValues={customFieldUserLocalValues}
                            customFieldFileValues={localCustomFieldFileValues}
                            changeCustomFieldTextValues={setCustomFieldTextLocalValues}
                            changeCustomFieldIntValues={setCustomFieldIntLocalValues}
                            changeCustomFieldFloatValues={setCustomFieldFloatLocalValues}
                            changeCustomFieldSelectValues={setCustomFieldSelectLocalValues}
                            changeCustomFieldDateValues={setCustomFieldDateLocalValues}
                            changeCustomFieldDatetimeValues={setCustomFieldDatetimeLocalValues}
                            changeCustomFieldUserValues={setCustomFieldUserLocalValues}
                            onAddCustomFieldFileValues={handleAddCustomFieldFileValues}
                            onRemoveCustomFieldFileValue={handleRemoveCustomFieldFileValue}
                            getFileUploadUrls={getFileUploadUrls}
                            onUpdateCustomFieldFileValue={handleUpdateCustomFieldFileValue}
                          />
                        )}
                      </>
                    );
                  })()}
              </Box>
            );
          })}
      </Box>

      {isDesktop && (
        <Flex
          justifyContent='flex-end'
          width='full'
          left='0'
          py='2'
          px='4'
          backgroundColor='neutral.0'
          borderTop='1px'
          borderColor='neutral.200'
          zIndex={101}
          gap={2}
          height={{ base: 'auto', md: '54px' }}
        >
          <Button colorScheme='gray' width='auto' onClick={handleCancel}>
            {t('actions.cancel')}
          </Button>
          {usePauseButton && (
            <Button variant='outline' colorScheme='primary' width='auto' onClick={handlePause}>
              {t('actions.pause')}
            </Button>
          )}

          <Button
            colorScheme='primary'
            isLoading={isSubmitting}
            type='submit'
            width='100px'
            onClick={handleSubmit(onSubmit)}
          >
            {submitButton}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default WorkOrderForm;
